<template>
  <div>
      <p v-if="lableText !== ''" class="label_css h-5">{{lableText}}</p>
      <div v-click-outside-input="closeList" class="relative" style="min-height: 52px">
          <input
          :maxlength="textMaxlength"
          @keypress="keyPressAction"
          @keypress.enter="enterkeyPressAction"
          @keyup="keyup" 
          @keydown="keydown"
          @blur="blurAction"
          @input="inputChangeAction(inputValue)"
          @click="onClickInputAction"
          @focus="focus"
          :disabled="disabled"
          :readonly="setReadOnly"
          v-model="inputValue"
          :placeholder="placholderText"
          type="text"
          :class="`pr-${inputValue.length > 0 ? '12' : '8'}
          ${showError ? 'border border-error text-error' : 'border border-gray-500 flex text-text1 focus:border-primary focus:ring-1'}
          `"
          :autofocus="setAutoFocust"
          autocomplete="off"
          :id="inputId"
          class="cust_text_field py-3 px-2  w-full text-text1 bg-white rounded-md items-center overFlowParaA">

          <span v-if="showArrow" :class="showPlus ? 'right-10' : 'right-3'" class="place-self-center absolute top-3.5">
              <p v-if="opened" class=" inline-flex mt-1"><i class="fas fa-chevron-up text-gray3 heading-6"></i></p>
              <span v-if="!opened" class="mt-2"><i class="fas fa-chevron-down text-gray3 heading-6"></i></span>
          </span>
          <span @click="addNew" v-if="showPlus" style="height: 50px;" class="place-self-center absolute top-0 right-2 border-gray-500 border-l flex justify-center items-center cursor-pointer">
              <span class=""><i class="pr-1 pl-2  fas fa-plus text-primary heading-6" ></i></span>
          </span>

          <span v-if="showClearIcon" class="cursor-pointer place-self-center absolute mt-px top-5" :class="`${inputValue.length > 0 ? 'visible' : 'invisible'} ${showClearIconNear ? ' right-9 ' : ' right-16'}`" >
            <img @click="clearInput" height="10px" width="10px" src="@/assets/images/svg/clear-input.svg" alt="clearInput">
          </span>

          <div :id="`${this.listId}`" class=" absolute z-10 flex-col w-full max-h-52 h-auto bg-white overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg " style="overflow-x: auto;" v-show="opened" >
              <div v-if="displayList.length > 0">
                  <div v-for="(data, index) in displayList" :key="index" :id="`${index}`"
                  :class="`${downPressCount === index ? 'bg-gray-200 border-gray-200' : ''} ${inputValue === data[keyName] ? 'bg-info border-info' : '' }`" style="min-height: 52px"
                  @click.stop.prevent="selectNewVal(data)" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" >
                  <div :class="`${data.isDisable ? 'text-gray2' : ''} whitespace-nowrap overflow-hidden heading-4 overFlowParaA`">
                      {{ data[keyName] }}
                  </div>
                  </div>
              </div>
              <div v-else>
              <div class=" heading-4 p-2">
                {{recordMessage !== '' ? recordMessage : 'No Record Found'}}
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import Vue from 'vue'
Vue.directive('click-outside-input', {
bind: function (el, binding, vnode) {
  el.clickOutsideEvent = function (event) {
    // here I check that click was outside the el and his children
    if (!(el === event.target || el.contains(event.target))) {
      // and if it did, call method provided in attribute value
      vnode.context[binding.expression](event)
    }
  }
  document.body.addEventListener('click', el.clickOutsideEvent)
},
unbind: function (el) {
  document.body.removeEventListener('click', el.clickOutsideEvent)
}
})
export default {
  props: [ "searchCallApi", "listId", "searchRecordMessage", "keyName", "items", "showPlus", "inputext", "textMaxlength", 'lableText', 'inputId', 'autoFocus', "showArrow", "placholderText", "showClearIcon", "showClearIconNear", "setReadOnly", "fieldError", "disabled"],
data() {
  return {
    scrollCount: null,
    downPressCount: null,
    displayList: [],
    showError: false,
    callSearch: false,
    setAutoFocust: false,
    opened: false,
    disabledBtn: false,
    inputValue: '',
    recordMessage: ''
  };
},
watch: {
  inputValue: {
    handler() {
      if (this.callSearch) {
          let filter = this.inputValue.toLowerCase();
          this.displayList = []
          for (let index = 0; index < this.items.length; index++) {
              if (this.inputValue.toLowerCase().indexOf(filter) > -1 === this.items[index][this.keyName].toLowerCase().indexOf(filter) > -1) {
               this.displayList.push(this.items[index])
              }
          }
      }
    }
  },
   searchRecordMessage: {
      handler () {
        this.recordMessage = this.searchRecordMessage
      }
  },
   fieldError: {
      handler () {
        this.showError = this.fieldError
      }
  },
   items: {
      handler () {
        this.displayList = this.items
      },
      deep: true
  },
  inputext: {
      handler () {
        this.callSearch = false
          if (this.inputext) {
              this.inputValue = this.inputext
          } else {
            this.inputValue = this.inputext
          }
      }
  }
},
mounted() {
  this.displayList = this.items
  this.showError = this.fieldError
  if (this.searchRecordMessage) {
    this.recordMessage = this.searchRecordMessage
  }
  if (this.disabled) {
    this.disabledBtn = this.disabled
  }
   setTimeout(() => {
    if (this.inputext) {
      this.inputValue = this.inputext
    } else {
      this.inputValue = ''
    }
   }, 200);
  setTimeout(() => {
    if (this.autoFocus) {
      document.getElementById(`${this.inputId}`).focus()
      this.$emit('onFocusAction')
    }
  }, 50);
},
methods: {
  checkKey (e) {
    e = e || window.event;
    if (e.keyCode === 40) {
      this.downArrowAction()
    }
    if (e.keyCode === 38) {
      this.upArrowAction()
    }
    // if (e.keyCode === 9) {
    //    this.closeList()
    // }
  },
  downArrowAction () {
    if (this.displayList.length - 1 > this.downPressCount && this.downPressCount !== null) {
      this.downPressCount = this.downPressCount + 1
      this.scrollCount = this.scrollCount + 52
      document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
    }
    if (this.downPressCount === null) {
      this.downPressCount = 0
      this.scrollCount = 0
      document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
    }
  },
  upArrowAction () {
    if (this.downPressCount > 0 && this.scrollCount > 0) {
      this.downPressCount = this.downPressCount - 1
    this.scrollCount = this.scrollCount - 52
    document.getElementById(`${this.listId}`).scrollTop = this.scrollCount
    }
    
  },
  inputChangeAction (text) {
     this.callSearch = true
     this.scrollCount = null
     this.downPressCount = null
      if (this.searchCallApi) {
      this.$emit('inputChangeAction', text)
    } else {
      this.callSearch = true
    }
  },
  keyPressAction () {
      this.$emit('keyPressAction')
      let documentvalue = document.getElementById(`${this.inputId}`)
      documentvalue.onkeydown = this.checkKey();
  },
  enterkeyPressAction () {
     document.getElementById(`${this.inputId}`).blur()
     if (this.displayList[this.downPressCount].isDisable) {
      console.log('this item is desable');
     } else {
       this.$emit("selectNewForVal", this.displayList[this.downPressCount], this.keyName);
     }
  },
  selectNewVal (data) {
    if (data.isDisable) {
      console.log('this is disable item');
    } else {
      document.getElementById(`${this.inputId}`).blur()
      this.$emit("selectNewForVal", data, this.keyName);
    }
     
  },
  keyup () {
      // this.$emit('keyup')
      // let documentvalue = document.getElementById(`${this.inputId}`)
      // documentvalue.onkeydown = this.checkKey();
  },
  keydown () {
      // this.$emit('keyup')
      let documentvalue = document.getElementById(`${this.inputId}`)
      documentvalue.onkeydown = this.checkKey();
  },
  blurAction () {
    console.log('blur', this.inputValue);
    setTimeout(() => {
      this.opened = false
      this.$emit('blurAction')
      if (this.inputValue !== '') {
        this.inputValue = this.inputext
      }
      if (this.inputValue === '') {
        this.$emit("selectNewForVal", null);
      }
      this.scrollCount = null
     this.downPressCount = null
    }, 400);
    
  },
  onClickInputAction () {
      this.$emit('onClickInputAction')
  },
  clearInput () {
    this.inputValue = ''
    this.$root.$emit('clearInputAction')
    document.getElementById(`${this.inputId}`).focus()
    setTimeout(() => {
      this.opened = true
    }, 120);
  },
  closeList () {
      this.$emit('closeList')
  },
  addNew () {
      this.$emit('addNew')
  },
  focus () {
    this.$emit('focus')
      this.displayList = this.items
      // setTimeout(() => {
      //     console.log('height', document.getElementById(`${this.keyName}`).offsetHeight);
      // }, 500);
      let rect = document.getElementById(`${this.inputId}`).getBoundingClientRect()
     if (window.innerHeight - rect.bottom < 200) {
        document.getElementById(`${this.listId}`).style.bottom = `52px`
     } else {
      document.getElementById(`${this.listId}`).style.bottom = "auto"
     }
    this.opened = true
  }
},
};
</script>
<style scoped>
</style>
