<template>
  <div v-if="dialog" class="popup_overlay">
  <div class="custom_dialog bg-white" style="width:890px;max-height:800px;">
    <div class="cust_card">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">{{this.componentMasterId > 0 ? 'Edit Component' : 'Add Component'}}</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addComponent()">Save</button>
        </div>  
      </div>
      <div class="pb-4" style="max-height: 700px!important;overflow-y: auto;min-height: 350px;">
        <div class="p-2 pb-0 grid grid-cols-12">
          <div class="box col-span-12 p-2">
            <p class="label_css">Component Name</p>
            <input
            :class="compNameErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
            placeholder="Component Name"
            @keyup="changeUnderScore(componentDetail.componentName, 'cName', '0')"
            v-model="componentDetail.componentName"
            >
            <p class="text-error" v-if="compNameErr">Component Name is Required</p>
          </div>
        </div>
        <div class="p-2 pt-0 relative rounded-lg m-2 mx-4 card" v-for="(data, index) in componentDetail.componentFieldList" :key="index" >
          <div class="p-2 -mb-2" v-if="componentDetail.componentFieldList.length === index + 1">
            <div class="flex items-center ">
              <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary" @click="checkboxCheck(index)" v-model="data.isSelectCheckox" >
              <span class="pl-3 text-text2 heading-5 cursor-pointer" @click="checkboxCheck(index)">Child Component</span>
            </div>
          </div>
          <div class="grid grid-cols-12" v-if="data.isSelectCheckox">
            <div class="box col-span-6 p-2">
              <Dropdown 
                :inputext="data.childComponentName" 
                :inputId="'jobloactioninput'"
                :lableText="'Select Child'"
                :placholderText="`Select Child`"
                :autoFocus="false"
                :showPlus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="false"
                :keyName="'componentName'"
                :listId="'componentMasterId'"
                :items="childCOmpList"
                @onClickInputAction="childCropCownID = index"
                @selectNewForVal="slectRadius"
                />
                <p class="text-error" v-if="data.fieldErr !== ''">{{data.fieldErr}}</p>
            </div>
            <div class="self-center pl-2" v-if="componentDetail.componentFieldList.length > 1">
              <button v-if="componentDetail.componentFieldList.length > 1" class=" rounded-full h-7 w-7 bg-red-500  mr-1"
                @click="removeItemDetail(index)"
                >
                <i class="fas fa-minus text-white heading-3 pt-1"></i>
              </button>
            </div>
            <!-- <div class="col-span-12 pl-2 pt-2">
              <div class="flex items-center pb-1 ">
                <input id="default-radio-3" v-model="data.isChildRepeatable" type="radio" value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Single Component</label>
              </div>
              <div class="flex items-center pb-1 col-span-6">
                <input id="default-radio-4" v-model="data.isChildRepeatable" type="radio" value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Repeatable Component</label>
              </div>
            </div> -->
          </div>
          <div class="grid grid-cols-12" v-if="!data.isSelectCheckox">
            <div class="box col-span-6 p-2">
              <Dropdown 
                :inputext="data.selFieldname" 
                :inputId="'jobloactioninput2'"
                :lableText="'Select Field'"
                :placholderText="`Select Field`"
                :autoFocus="false"
                :showPlus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="false"
                :keyName="'fieldName'"
                :listId="'fieldMasterId'"
                :items="inputFieldList"
                @onClickInputAction="SelectFieldID = index"
                @selectNewForVal="selectName"
                />
                <p class="text-error" v-if="data.fieldErr !== ''">{{data.fieldErr}}</p>
            </div>
            <div class="box p-2" :class="componentDetail.componentFieldList.length === 1 ? 'col-span-6' : 'col-span-5'">
              <p class="label_css">Display Name</p>
              <input
              @keyup="changeUnderScore(data.fieldName, 'dName', index)"
              :class="data.displayErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
              class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
              placeholder="Display Name"
              v-model="data.fieldName"
              >
              <p class="text-error" v-if="data.displayErr">Display Name is Required</p>
            </div>
            <div class="self-center mt-4 pl-2" v-if="componentDetail.componentFieldList.length > 1">
              <button v-if="componentDetail.componentFieldList.length > 1" class=" rounded-full h-7 w-7 bg-red-500  mr-1"
                @click="removeItemDetail(index)"
                >
                <i class="fas fa-minus text-white heading-3 pt-1"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="p-2 pt-0 flex pb-2">
          <p class=" text-blue-500 pl-2 heading-5 cursor-pointer" @click="addEle()">+ Add Element</p>
        </div>
      </div>
    </div>
    <div v-if="showAdminDialog" class="popup_overlay">
      <div persistent class="custom_dialog w-96" >
      <div class="bg-white rounded-sm p-4">
        <p class="heading-1 font-bold" primary-title>
          Are You Sure ?
        </p>
        <p class="pt-3 heading-4" >Do you want to make <span class="font-bold text-primary">{{employeeTypeObj.firstName }} {{employeeTypeObj.lastName}}</span> super Admin?</p>
          <div class="text-right pt-4">
            <button
              class=" bg-gray-200 cta-text text-text1 py-1.5 px-5 mr-2 rounded-3xl"
              depressed=""
              rounded
              @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = false"
            >
              NO
            </button>
            <button class="bg-primary text-white font-bold px-5 py-1.5 rounded-2xl" @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = true">
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import HRM from '@/View/CMS/api/HRM.js'
import Dropdown from '@/View/components/dropdown.vue'
export default {
  components: {
    Dropdown,
  },
  props: ['componentMasterId'],
  data () {
    return {
      SelectFieldID: 1,
      childCropCownID: 1,
      selectedStatus: '',
      inputFieldList: [],
      shortedList: [],
      childCOmpList: [],
      displayBranch:false,
      emailOverlap: null,
      showAdminDialog: false,
      showDeleteBtn: false,
      showPassword: false,
      passwordVal: '',
      userDetailValue: [],
      compNameErr: false,
      errVal: true,
      componentDetail: {
        componentMasterId: 0,
        projectDetailId: parseInt(this.$route.params.projectId),
        componentName: '',
        isActive: true,
        componentFieldList: [
          {
            componentFieldMappingId: 0,
            fieldMasterId: 0,
            fieldName: '',
            fieldDataType: '',
            selFieldname: '',
            isOpenList: false,
            fieldErr: '',
            displayErr: false,
            childComponentId: 0,
            childComponentName: '',
            isSelectCheckox: false,
            isChildRepeatable: true,
          }
        ],
      },
      submitted: false,
      shiftlist: [],
      selectedShiftId: '',
      Obj2: {},
      Obj: {},
      cardView: false,
      dialog: true,
      employeeTypeList: []
    }
  },
  validations: {
  },
  computed: {
  },
  mounted () {
    console.log('this.$route.params.componentId', this.$route.params)
    console.log('componentMasterId', this.componentMasterId)
    if (this.componentMasterId > 0) {
      this.getComponentDetail()
    }
    this.inputFieldReqItem()
    this.GetCompList()
  },
  watch: {
    componentDetail: {
      handler () {
        if (this.componentDetail.componentName !== '') {
          this.compNameErr = false
          this.errVal = false
        }
        for (let index = 0; index < this.componentDetail.componentFieldList.length; index++) {
          let ele = this.componentDetail.componentFieldList[index]
          if (ele.fieldMasterId !== 0 || ele.fieldDataType !== '') {
            ele.fieldErr = ''
            this.errVal = false
          }
          if (ele.fieldName !== '') {
            ele.displayErr = false
            this.errVal = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    focus () {
      console.log('click')
    },
    slectRadius (data) {
      console.log('data', data, this.childCropCownID)
      this.componentDetail.componentFieldList[this.childCropCownID].childComponentName = data.componentName
      this.componentDetail.componentFieldList[this.childCropCownID].childComponentId = data.componentMasterId
    },
    selectStatus (data) {
      console.log('data 1', data)
    },
    checkboxCheck (index) {
      this.componentDetail.componentFieldList[index].childComponentId = 0
      this.componentDetail.componentFieldList[index].childComponentName = ''
      this.componentDetail.componentFieldList[index].displayErr = false
      this.componentDetail.componentFieldList[index].fieldDataType = ''
      this.componentDetail.componentFieldList[index].fieldMasterId = 0
      this.componentDetail.componentFieldList[index].fieldName = ''
      this.componentDetail.componentFieldList[index].fieldErr = ''
      this.componentDetail.componentFieldList[index].isChildRepeatable = true
      this.componentDetail.componentFieldList[index].selFieldname = ''
      this.componentDetail.componentFieldList[index].isSelectCheckox = !this.componentDetail.componentFieldList[index].isSelectCheckox
    },
    changeUnderScore (value, from, index) {
      if (from === 'cName') {
        this.componentDetail.componentName = value.replace(/ /g,"_")
      } else if (from === 'dName') {
        this.componentDetail.componentFieldList[index].fieldName = value.replace(/ /g,"_")
      }
    },
    GetCompList () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRM.componentListing(
        0,
        500,
        '',
        '',
        '',
        true,
        parseInt(this.$route.params.projectId),
        response => {
          this.childCOmpList = response.Data.tableRow === null ? [] : response.Data.tableRow
          console.log('this.childCOmpList', this.childCOmpList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addEle() {
      this.inputFieldReqItem()
      this.componentDetail.componentFieldList.push({
        componentFieldMappingId: 0,
        fieldMasterId: 0,
        fieldName: '',
        fieldDataType: '',
        selFieldname: '',
        isOpenList: false,
        childComponentId: 0,
        childComponentName: '',
        isSelectCheckox: false,
        isChildRepeatable: true,
      })
    },
    removeItemDetail(index) {
      if (this.componentDetail.componentFieldList.length !== 1) {
        this.componentDetail.componentFieldList.splice(index, 1)
      }
    },
    selectName(val) {
      console.log('val', val, this.componentDetail.componentFieldList[this.SelectFieldID])
      if (val !== null) {
        this.componentDetail.componentFieldList[this.SelectFieldID].fieldMasterId = val.fieldMasterId
        this.componentDetail.componentFieldList[this.SelectFieldID].fieldDataType = val.fieldDataType
        this.componentDetail.componentFieldList[this.SelectFieldID].selFieldname = val.fieldName
        this.componentDetail.componentFieldList[this.SelectFieldID].isOpenList = false
      }
    },
    resizeGrid () {
      if (window.innerWidth < 960) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    addComponent () {
        if (this.componentDetail.componentName === '') {
          this.compNameErr = true
          this.errVal = true
        }
        for (let index = 0; index < this.componentDetail.componentFieldList.length; index++) {
          console.log('this.componentDetail.componentFieldList', this.componentDetail.componentFieldList)
          let ele = this.componentDetail.componentFieldList[index]
          console.log('ELE', ele)
          // if (ele.isChildRepeatable === 'true') {
          //   ele.isChildRepeatable = true
          // } else if (ele.isChildRepeatable === 'false') {
          //   ele.isChildRepeatable = false
          // }
          if (!ele.isSelectCheckox) {
            if (ele.fieldMasterId === 0 && ele.fieldDataType === '') {
              ele.fieldErr = 'Field Value is Required'
              this.errVal = true
            }
            if (ele.fieldName === '') {
              ele.displayErr = true
              this.errVal = true
            }
          } else {
            if (ele.childComponentName === '' && ele.childComponentId === 0) {
              ele.fieldErr = 'Child Value is Required'
              this.errVal = true
            }
          }
        }
        console.log('this.errVal', this.errVal)
        if (this.errVal === false) {
          console.log(this.componentDetail)
          this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
          HRM.addEditComponent(
            this.componentDetail,
            response => {
              // console.log(response)
              this.$root.$emit('closeComponentDialog', false)
              this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              // console.log(error)
              this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
    },
    getComponentDetail () {
        console.log('this.componentDetail', this.componentDetail)
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        HRM.getCompDetail(
          this.componentMasterId,
          response => {
            let tempData = response.Data
            console.log('detail api req', tempData)
            this.componentDetail.componentMasterId = JSON.parse(tempData.componentMasterId)
            this.componentDetail.projectDetailId = JSON.parse(tempData.projectDetailId)
            this.componentDetail.componentName = tempData.componentName
            this.componentDetail.isActive = JSON.parse(tempData.isActive)
            if (tempData.componentFieldList !== null) {
              this.componentDetail.componentFieldList = []
              for (let index = 0; index < tempData.componentFieldList.length; index++) {
                console.log('tempData.componentFieldList[index]', tempData.componentFieldList[index])
                let obj = {
                  componentFieldMappingId: tempData.componentFieldList[index].componentFieldMappingId,
                  fieldMasterId: tempData.componentFieldList[index].fieldMasterId,
                  fieldName: tempData.componentFieldList[index].filedName,
                  fieldDataType: tempData.componentFieldList[index].fieldDataType,
                  isOpenList: false,
                  selFieldname: '',
                  fieldErr: '',
                  displayErr: '',
                  childComponentId: tempData.componentFieldList[index].childComponentId,
                  childComponentName: tempData.componentFieldList[index].childComponentName,
                  isSelectCheckox: tempData.componentFieldList[index].childComponentId > 0 ? true : false,
                  isChildRepeatable: tempData.componentFieldList[index].isChildRepeatable,
                }
                this.childCOmpList.forEach(element => {
                  if (element.childComponentId === tempData.componentFieldList[index].childComponentId) {
                    obj.childComponentName = element.componentName
                    obj.isSelectCheckox = true
                  }
                })
                this.inputFieldList.forEach(element => {
                  if (element.fieldMasterId === tempData.componentFieldList[index].fieldMasterId) {
                    obj.selFieldname = element.fieldName
                  }
                })
                console.log('obj`````````````````', obj)
                // if (tempData.componentFieldList[index].fieldMasterId === 0 && tempData.componentFieldList[index].fieldDataType === '') {
                //   obj.fieldErr = true
                // } else {
                //   obj.fieldErr = false
                // }
                // if (tempData.componentFieldList[index].fieldName === '') {
                //   obj.displayErr = true
                // } else {
                //   obj.displayErr = false
                // }
                this.componentDetail.componentFieldList.push(obj)
              }
            }
            console.log('this.componentDetail 123', this.componentDetail)
            console.log('response.data', response.Data)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    inputFieldReqItem () {
      HRM.inputFieldListing(
        true,
        response => {
          this.inputFieldList = response.Data
          this.shortedList = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    closeDetails () {
      this.$root.$emit('closeComponentDialog', true)
    }
  }
}
</script>
<style scoped>
.profiles {                         
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-item-group,
.v-expansion-panels {
  z-index: 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 0 20px 0 !important;
}
.content {
  padding: 5px 5px;
}
.checkbox {
  color: grey;
  font-size: 14px;
}
.checkbox1 {
  color: grey;
  font-size: 11px;
}
.checkbox2 {
  color: grey;
  font-size: 11px;
}
.buttons .button {
  margin-bottom: 0rem;
}
.input:focus {
  outline: none;
  border-color: 0 0 0 0.125em rgba(31, 82, 190, 0.25);
  box-shadow: none;
}
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: white;
  color: black;
}
.datetimepicker .datetimepicker-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  position: relative;
  padding: 0.3rem 1rem;
}
.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: 0rem;
}
.autocomplete .dropdown-content {
  overflow: auto;
  max-height: 200px;
}
.buttons:last-child {
  margin-bottom: 0rem;
}
</style>
